import { Spinner } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components";
import { PAGE_WIDGET_ID, WidgetType } from "legacy/constants/WidgetConstants";
import { APP_MODE } from "legacy/reducers/types";

import BaseWidget, { WidgetPropsRuntime, WidgetState } from "./BaseWidget";

const SpinnerContainer = styled.div<{ appMode: APP_MODE }>`
  display: flex;
  min-height: calc(
    ${(props) => (props.appMode === APP_MODE.EDIT ? 50 : 100)}vh -
      ${(props) => props.theme.legacy.headerHeight}
  );
  justify-content: center;
  align-items: center;
`;

const SkeletonWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

class SkeletonWidget extends BaseWidget<SkeletonWidgetProps, WidgetState> {
  getPageView() {
    if (this.props.widgetId === PAGE_WIDGET_ID) {
      return (
        <SpinnerContainer appMode={this.props.appMode} data-test="skeleton">
          <Spinner />
        </SpinnerContainer>
      );
    } else {
      return <SkeletonWrapper className="bp5-skeleton" data-test="skeleton" />;
    }
  }

  getWidgetType(): WidgetType {
    return "SKELETON_WIDGET";
  }
}

export interface SkeletonWidgetProps extends WidgetPropsRuntime {
  isLoading: boolean;
  loadedType: WidgetType;
}

export default SkeletonWidget;
