import { Placement } from "@blueprintjs/core";
import {
  Dimension,
  PerCornerBorderRadius,
  PerSideBorder,
  WidgetTypes,
} from "@superblocksteam/shared";
import React, { useCallback, useMemo, useState } from "react";
import {
  PropertyPaneConfig,
  PropsPanelCategory,
} from "legacy/constants/PropertyControlConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { TextAlign } from "legacy/constants/WidgetConstants";
import {
  VALIDATION_TYPES,
  BASE_WIDGET_VALIDATION,
  WidgetPropertyValidationType,
} from "legacy/constants/WidgetValidation";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import {
  getCurrentPageUrlState,
  getRoutes,
} from "legacy/selectors/routeSelectors";
import { GeneratedTheme, TextStyleWithVariant } from "legacy/themes";
import { getSystemQueryParams } from "legacy/utils/queryParams";
import { createPerCornerBorderRadius } from "pages/Editors/AppBuilder/Sidebar/BorderRadiusEditor";
import { useAppSelector } from "store/helpers";
import BaseWidget, { WidgetPropsRuntime } from "../BaseWidget";
import { ButtonStyle } from "../Shared/Button";
import { iconPositionProperty, iconProperty } from "../appearanceProperties";
import {
  getWidgetDefaultPadding,
  ZERO_PADDING,
} from "../base/sizing/canvasSizingUtil";
import {
  paddingProperty,
  sizeSection,
  visibleProperties,
} from "../basePropertySections";
import {
  backgroundColorProperty,
  borderProperty,
  borderRadiusProperty,
  textStyleCombinedProperty,
} from "../styleProperties";
import withMeta, { WithMeta } from "../withMeta";
import MenuComponent, { MenuComponentProps } from "./MenuComponent";
import { transformItem } from "./transform";
import { ComponentMenuItem, ManualMenuItem, MenuChildrenType } from "./types";
import {
  borderDefaultValue,
  DEFAULT_MENU_WIDGET_BUTTON_STYLE_VARIANT,
  DEFAULT_MENU_WIDGET_MENU_ITEM_STYLE_VARIANT,
  textColorDefaultValue,
} from "./utils";
import type { AppState } from "store/types";

export interface MenuWidgetProps extends WidgetPropsRuntime, WithMeta {
  isDisabled?: boolean;
  isVisible?: boolean;
  childrenType: MenuChildrenType;
  manualChildren?: ManualMenuItem[];

  // Button props
  buttonProps: {
    text?: string;
    textStyle?: TextStyleWithVariant;
    backgroundColor?: string;
    buttonStyle?: ButtonStyle;
    icon?: string;
    iconPosition?: "LEFT" | "RIGHT";
    textAlignment?: TextAlign;
    border?: PerSideBorder;
    borderRadius?: PerCornerBorderRadius;
  };

  menuProps: {
    placement?: Placement;
    textStyle?: TextStyleWithVariant;
  };
}

const InternalMenuWidget = (props: MenuWidgetProps) => {
  const routes = useAppSelector(getRoutes);
  const currentPageUrlState = useAppSelector(getCurrentPageUrlState);
  const systemQueryParams = useAppSelector(getSystemQueryParams);
  const appMode = useAppSelector(getAppMode);
  const isDraggingOrResizingWidget = useAppSelector(
    (state: AppState) =>
      state.legacy.ui.widgetDragResize.isDragging ||
      state.legacy.ui.widgetDragResize.isResizing,
  );
  const [isOpen, setIsOpen] = useState(false);
  const context = useMemo(() => {
    return {
      routes,
      currentPageUrlState,
      systemQueryParams,
      appMode,
    };
  }, [routes, currentPageUrlState, appMode, systemQueryParams]);

  // We re-calculate items when the menu is opened
  const [menuItems, setMenuItems] = useState<ComponentMenuItem[]>([]);
  const getMenuItems = useCallback(() => {
    return (props.manualChildren ?? []).map((child) =>
      transformItem(child, context),
    );
  }, [context, props.manualChildren]);

  const handleBeforeMenuOpen = useCallback(() => {
    setMenuItems(getMenuItems());
    setIsOpen(true);
  }, [getMenuItems, setIsOpen]);

  const handleOnClose = useCallback(
    (e?: React.SyntheticEvent<HTMLElement, Event>) => {
      const forceNewTab =
        e && "ctrlKey" in e && "metaKey" in e && (e.ctrlKey || e.metaKey);

      if (!forceNewTab) {
        // keep the menu open if ctrl/cmd is pressed
        setIsOpen(false);
        setMenuItems([]);
      }
    },
    [setIsOpen, setMenuItems],
  );

  if (props.childrenType !== MenuChildrenType.Manual) {
    throw new Error(`Non-manual children types are not implemented yet`);
  }

  const buttonProps: MenuComponentProps["buttonProps"] = {
    ...props.buttonProps,
    disabled: props.isDisabled,
    width: props.width,
    maxWidth: props.maxWidth,
  };

  return (
    <MenuComponent
      items={menuItems}
      buttonProps={buttonProps}
      menuProps={props.menuProps}
      isOpen={isOpen && !isDraggingOrResizingWidget}
      onClose={handleOnClose}
      onBeforeMenuOpen={handleBeforeMenuOpen}
      widgetId={props.widgetId}
    />
  );
};

class MenuWidget extends BaseWidget<MenuWidgetProps, never> {
  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "buttonProps.text",
            label: "Label",
            helpText: "Sets the label of the button",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter button label text",
            isBindProperty: true,
            isTriggerProperty: false,
            visibility: "SHOW_NAME",
            isRemovable: true,
            defaultValue: "Menu",
          },
          {
            hidden: () => true, // TODO: re-introduce this radio group when adding mapped children
            propertyName: "childrenType",
            label: "Items",
            helpText: "Menu items to be displayed in the menu",
            controlType: "RADIO_BUTTON_GROUP",
            options: [
              {
                value: MenuChildrenType.Manual,
                label: "Manual",
              },
              {
                value: MenuChildrenType.Mapped,
                label: "Mapped",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "manualChildren",
            label: "Items",
            headerControlType: "ADD_MENU_ITEM",
            controlType: "MENU_ITEMS_INPUT",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          paddingProperty({
            propertyName: "buttonProps.padding",
            label: "Button padding",
            themeValue: ({ theme }) => {
              const value = getWidgetDefaultPadding(theme, {
                type: WidgetTypes.BUTTON_WIDGET,
              });
              const treatAsNull = value === ZERO_PADDING;
              return {
                treatAsNull,
                value: value,
              };
            },
          }),
          ...visibleProperties({ useJsExpr: false }),
        ],
      },
      sizeSection({
        widthSupportsFitContent: true,
        heightSupportsFitContent: true,
        hidePadding: true,
      }),
      {
        sectionName: "Button appearance",
        sectionCategory: PropsPanelCategory.Appearance,
        children: [
          {
            propertyName: "buttonProps.buttonStyle",
            label: "Button style",
            controlType: "DROP_DOWN",
            helpText: "Changes the style of the button",
            options: [
              {
                label: "Primary button",
                value: "PRIMARY_BUTTON",
              },
              {
                label: "Secondary button",
                value: "SECONDARY_BUTTON",
              },
              {
                label: "Tertiary button",
                value: "TERTIARY_BUTTON",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
          },
          iconProperty({
            label: "Button icon",
            propertyName: "buttonProps.icon",
          }),
          iconPositionProperty({
            label: "Button icon position",
            propertyName: "buttonProps.iconPosition",
          }),
          textStyleCombinedProperty({
            label: "Button text style",
            textStyleParentDottedPath: "buttonProps",
            defaultValueFn: {
              variant: () => DEFAULT_MENU_WIDGET_BUTTON_STYLE_VARIANT,
              "textColor.default": ({ props, theme }) =>
                textColorDefaultValue(props, theme),
            },
            additionalUserSelectableVariants: ["buttonLabel"],
          }),
          backgroundColorProperty({
            propertyNamespaceDottedPath: "buttonProps",
            helpText: "Changes the color of the background of the menu button",
            label: "Button background",
            themeValue: ({ props }) =>
              props.buttonProps.buttonStyle === "PRIMARY_BUTTON"
                ? { value: "colors.primary500", treatAsNull: false }
                : { value: "transparent", treatAsNull: true },
          }),
          borderProperty({
            label: "Button border",
            propertyNamespaceDottedPath: "buttonProps",
            defaultValueFn: ({ props, theme }) =>
              borderDefaultValue(props, theme),
            themeValue: ({
              props,
              theme,
            }: {
              props: MenuWidgetProps;
              theme: GeneratedTheme;
            }) => {
              return {
                value: borderDefaultValue(props, theme),
                treatAsNull:
                  props.buttonProps.buttonStyle !== "SECONDARY_BUTTON",
              };
            },
          }),
          borderRadiusProperty({
            label: "Button border radius",
            propertyName: "borderRadius",
            propertyNamespaceDottedPath: "buttonProps",
            themeValue: ({
              theme,
              props,
            }: {
              theme: GeneratedTheme;
              props: MenuWidgetProps;
            }) => {
              return {
                value: createPerCornerBorderRadius(
                  theme.borderRadius ?? Dimension.px(4),
                ),
                treatAsNull:
                  props.buttonProps.buttonStyle === "TERTIARY_BUTTON",
              };
            },
            hidden: (props: MenuWidgetProps) =>
              props.buttonProps.buttonStyle === "TERTIARY_BUTTON",
          }),
          {
            propertyName: "buttonProps.textAlignment",
            propertyCategory: PropsPanelCategory.Appearance,
            helpText: "The horizontal alignment of the button label",
            label: "Button label alignment",
            controlType: "RADIO_BUTTON_GROUP",
            defaultValue: "CENTER",
            hidden: (props: MenuWidgetProps) => {
              return props.width.mode === "fitContent";
            },
            options: [
              {
                icon: "LEFT_ALIGN",
                value: TextAlign.LEFT,
              },
              {
                icon: "CENTER_ALIGN",
                value: TextAlign.CENTER,
              },
              {
                icon: "RIGHT_ALIGN",
                value: TextAlign.RIGHT,
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
          },
        ],
      },
      {
        sectionName: "Menu appearance",
        sectionCategory: PropsPanelCategory.Appearance,
        children: [
          textStyleCombinedProperty({
            label: "Menu text style",
            textStyleParentDottedPath: "menuProps",
            defaultValueFn: {
              variant: () => DEFAULT_MENU_WIDGET_MENU_ITEM_STYLE_VARIANT,
              "textColor.default": ({ theme }) => theme?.colors.neutral700,
            },
          }),
          {
            propertyName: "menuProps.placement",
            label: "Menu position",
            helpText: "The placement of the menu popover when open",
            defaultValue: "bottom-start",
            controlType: "RADIO_BUTTON_GROUP",
            hideLabelsForUnselected: true,
            options: [
              {
                value: "bottom-start",
                label: "Below/above",
                icon: "VERTICAL_BOTTOM",
              },
              {
                value: "right-start",
                label: "Beside",
                icon: "ICON_RIGHT_ALIGN",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
          },
        ],
      },
      {
        sectionName: "Interaction",
        sectionCategory: PropsPanelCategory.Interaction,
        children: [
          {
            propertyName: "isDisabled",
            label: "Disabled",
            helpText: "Disables user interaction with this component",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: VALIDATION_TYPES.BOOLEAN,
          },
        ],
      },
    ];
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      "buttonProps.text": VALIDATION_TYPES.TEXT,
      "buttonProps.icon": VALIDATION_TYPES.ICONS,
      "buttonProps.style": VALIDATION_TYPES.TEXT,
      "buttonProps.border": VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
      "buttonProps.borderRadius": VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
      manualChildren: VALIDATION_TYPES.OBJECT,
    };
  }

  getPageView() {
    return <InternalMenuWidget {...this.props} />;
  }

  getWidgetType(): WidgetType {
    return "MENU_WIDGET";
  }
}

export default MenuWidget;

export const ConnectedMenuWidget = withMeta(MenuWidget);
