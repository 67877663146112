import { Dimension, Padding } from "@superblocksteam/shared";
import moment from "moment-timezone";
import {
  VerticalAlign,
  CodeWidgetLanguages,
  ModalSize,
  SLIDEOUT_DEFAULT_COLUMNS,
  MODAL_COLUMNS,
  WidgetTypes,
  WidgetType,
  SectionDefaults,
  CanvasDefaults,
  GridDefaults,
  Breakpoints,
  WidgetWidthModes,
  WidgetHeightModes,
  WidgetLabelPosition,
  TextAlign,
} from "legacy/constants/WidgetConstants";
import createConfigChanged from "legacy/mockResponses/onCreateConfigChanged";
import { type GeneratedTheme } from "legacy/themes/types";
import { estimateInitialButtonWidgetHeightGU } from "legacy/widgets/ButtonWidget/ButtonComponent";
import { CalloutType } from "legacy/widgets/CalloutWidget/Constants";
import { estimateInitialCheckboxWidgetHeightGU } from "legacy/widgets/CheckboxWidget/CheckboxComponent";
import { DatePickerType } from "legacy/widgets/DatePickerWidget/Constants";
import { estimateInitialDatepickerWidgetHeightGU } from "legacy/widgets/DatePickerWidget/DatePickerComponentWithLayoutManaged";
import { DiffMethod } from "legacy/widgets/DiffWidget/DiffMethod";
import { estimateInitialDropdownWidgetHeightGU } from "legacy/widgets/DropdownWidget/DropdownComponentWIthLayoutManaged";
import {
  LinkToType,
  MenuChildrenType,
  MenuItemType,
} from "legacy/widgets/MenuWidget/types";
import { estimateInitialSwitchWidgetHeightGU } from "legacy/widgets/SwitchWidget/SwitchComponent";
import { LEGACY_DEFAULT_CELL_TEXT_SIZE } from "legacy/widgets/TableWidget/TableComponent/Constants";
import {
  CompactModeTypes,
  PaginationTypes,
} from "legacy/widgets/TableWidget/TableComponent/types";
import { estimateInitialTextWidgetHeightGU } from "legacy/widgets/TextWidget/TextComponent";
import { Flags } from "store/slices/featureFlags";
import type { WidgetProps, WidgetTypeToPropType } from "legacy/widgets";

const POS_ZERO = Object.freeze({
  top: Dimension.gridUnit(0),
  left: Dimension.gridUnit(0),
});

export type NumGetter =
  | Dimension<"gridUnit">
  | ((parent: WidgetProps) => Dimension<"gridUnit">);

export type DimensionGetter =
  | Dimension<"fitContent" | "gridUnit">
  | ((parent: WidgetProps) => Dimension<"fitContent" | "gridUnit">);

type View<Type = WidgetType> = {
  type: Type;
  size?: {
    height: DimensionGetter;
    width: NumGetter;
  };
  position: {
    top?: NumGetter;
    left?: NumGetter;
    bottom?: NumGetter;
    right?: NumGetter;
  };
  props: Record<string, any> & { blueprint?: WidgetBlueprint };
};

export type WidgetBlueprint = {
  view?: Array<View>;
};

export interface WidgetConfigProps {
  height: Dimension<WidgetHeightModes>;
  width: Dimension<WidgetWidthModes>;
  blueprint?: WidgetBlueprint;
  widgetName: string;
  docsUrl?: string; // TODO: This gets placed into our DSL and it should not be there
}

type WidgetCreateConfig<key extends keyof WidgetTypeToPropType> = Partial<
  WidgetTypeToPropType[key]
> &
  WidgetConfigProps;

type WidgetCreateConfigs = {
  [key in keyof WidgetTypeToPropType]: WidgetCreateConfig<key>;
};

interface WidgetConfigReducerState {
  getCreateConfig: (
    key: keyof WidgetTypeToPropType,
    flags: Flags,
    theme?: GeneratedTheme,
  ) => WidgetCreateConfig<keyof WidgetTypeToPropType>;
}

const ROW_HEIGHT_MIGRATION = 3;
const COLUMN_WIDTH_MIGRATION = 6;

const getContainerPaddingHeightGU = (theme?: GeneratedTheme) => {
  if (theme && theme.container.canvas.padding) {
    const padding = Padding.y(theme.container.canvas.padding);
    return Dimension.toGridUnit(
      padding,
      GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    ).roundUp().value;
  }
  return 2;
};

export const getBlueprints = (flags: Flags, theme?: GeneratedTheme) => {
  const blueprints: WidgetCreateConfigs = {
    BUTTON_WIDGET: {
      text: "Submit",
      buttonStyle: "PRIMARY_BUTTON",
      height: Dimension.fitContent(
        estimateInitialButtonWidgetHeightGU(theme) ?? 1 * ROW_HEIGHT_MIGRATION,
      ),
      width: Dimension.gridUnit(2 * COLUMN_WIDTH_MIGRATION),
      widgetName: "Button",
      isDisabled: false,
      isVisible: true,
      collapseWhenHidden: true,
      animateLoading: true,
      isDefaultClickDisabled: true,
      docsUrl: "https://docs.superblocks.com/components/button",
      iconPosition: "LEFT",
    },
    TEXT_WIDGET: {
      text: "Label",
      textStyle: "LABEL",
      textType: "text",
      textAlign: TextAlign.LEFT,
      verticalAlign: VerticalAlign.AUTO,
      height: Dimension.fitContent(estimateInitialTextWidgetHeightGU(theme)),
      width: Dimension.gridUnit(4 * COLUMN_WIDTH_MIGRATION),
      widgetName: "Text",
      isVisible: true,
      collapseWhenHidden: true,
      animateLoading: true,
    },
    RICH_TEXT_EDITOR_WIDGET: {
      label: "Text",
      defaultValue: "<b>Type</b> your own text",
      height: Dimension.gridUnit(8 * ROW_HEIGHT_MIGRATION),
      width: Dimension.gridUnit(8 * COLUMN_WIDTH_MIGRATION),
      isDisabled: false,
      isVisible: true,
      collapseWhenHidden: true,
      animateLoading: true,
      widgetName: "RichTextEditor",
      convertMarkdown: true,
    },
    IMAGE_WIDGET: {
      defaultImage:
        "https://res.cloudinary.com/drako999/image/upload/v1589196259/default.png",
      image: "https://lowcode.s3.us-west-2.amazonaws.com/eilish-billie.jpeg",
      imageShape: "RECTANGLE",
      maxZoomLevel: 1,
      width: Dimension.gridUnit(4 * COLUMN_WIDTH_MIGRATION),
      height: Dimension.gridUnit(4 * ROW_HEIGHT_MIGRATION),
      fillContainer: false,
      widgetName: "Image",
      isVisible: true,
      collapseWhenHidden: true,
      animateLoading: true,
    },
    INPUT_WIDGET: {
      inputType: "TEXT",
      height: Dimension.gridUnit(5),
      label: "Label",
      placeholderText: "Enter text",
      width: Dimension.gridUnit(3 * COLUMN_WIDTH_MIGRATION),
      widgetName: "Input",
      isVertical: true,
      isVisible: true,
      collapseWhenHidden: true,
      docsUrl: "https://docs.superblocks.com/components/input",
      animateLoading: true,
      iconPosition: "LEFT",
    },
    // SWITCH_WIDGET: {
    //   isOn: false,
    //   label: "Switch",
    //   rows: Dimension.gridUnit(1 * ROW_HEIGHT_MIGRATION),
    //   columns: 4 * COLUMN_WIDTH_MIGRATION,
    //   widgetName: "Switch",
    // },
    ICON_WIDGET: {
      icon: "home",
      widgetName: "Icon",
      height: Dimension.fitContent(3),
      width: Dimension.gridUnit(3),
      isVisible: true,
      collapseWhenHidden: true,
      animateLoading: true,
    },
    CONTAINER_WIDGET: {
      height: Dimension.fitContent(
        GridDefaults.EMPTY_CONTAINER_EDIT_MODE_HEIGHT +
          getContainerPaddingHeightGU(theme),
      ),
      width: Dimension.gridUnit(8 * COLUMN_WIDTH_MIGRATION),
      widgetName: "Container",
      shouldScrollContents: true,
      children: [],
      isVisible: true,
      collapseWhenHidden: true,
      blueprint: {
        view: [
          {
            type: "CANVAS_WIDGET",
            position: POS_ZERO,
            props: {
              canExtend: false,
              detachFromLayout: true,
              children: [],
            },
          },
        ],
      },
    },
    DATE_PICKER_WIDGET: {
      isDisabled: false,
      datePickerType: DatePickerType.DATE_PICKER,
      height: Dimension.fitContent(
        estimateInitialDatepickerWidgetHeightGU(theme),
      ),
      label: "Date",
      dateFormat: "DD/MM/YYYY HH:mm",
      width: Dimension.gridUnit(4 * COLUMN_WIDTH_MIGRATION),
      widgetName: "DatePicker",
      defaultDate: moment().format("DD/MM/YYYY HH:mm"),
      isVisible: true,
      collapseWhenHidden: true,
      animateLoading: true,
      twentyFourHourTime: true,
      showIcon: true,
    },
    VIDEO_WIDGET: {
      height: Dimension.gridUnit(7 * ROW_HEIGHT_MIGRATION),
      width: Dimension.gridUnit(7 * COLUMN_WIDTH_MIGRATION),
      widgetName: "Video",
      url: "https://www.youtube.com/watch?v=uxpDa-c-4Mc",
      autoPlay: false,
      isVisible: true,
      collapseWhenHidden: true,
      animateLoading: true,
    },
    TABLE_WIDGET: {
      height: Dimension.gridUnit(10 * ROW_HEIGHT_MIGRATION),
      width: Dimension.gridUnit(11 * COLUMN_WIDTH_MIGRATION),
      label: "Data",
      widgetName: "Table",
      textSize: LEGACY_DEFAULT_CELL_TEXT_SIZE,
      compactMode: CompactModeTypes.SHORT,
      horizontalAlignment: "LEFT",
      verticalAlignment: "CENTER",
      primaryColumns: {},
      derivedColumns: {},
      cachedColumnSettings: {},
      tableHeader: "Users",
      isSearchable: true,
      isFilterable: true,
      isDownloadable: true,
      isVisible: true,
      collapseWhenHidden: true,
      animateLoading: true,
      defaultFilters: JSON.stringify({}),
      defaultSelectedRow: "0",
      pageType: PaginationTypes.NONE,
      ...{
        cellProps: {
          textStyle: {
            variant: "body2",
          } as any,
        },
      },
      tableData: JSON.stringify(
        [
          {
            name: "Billie Eilish",
            twitter: "https://twitter.com/billieeilish",
            date_joined: "2019-01-06",
            photo:
              "https://lowcode.s3-us-west-2.amazonaws.com/billie_eilish.png",
            email: "bad_guy@gmail.com",
            action: "",
          },
          {
            name: "Thom Yorke",
            twitter: "https://twitter.com/thomyorke",
            date_joined: "2019-01-05",
            photo: "https://lowcode.s3-us-west-2.amazonaws.com/thom_yorke.png",
            email: "karma_police_thom@hotmail.com",
            action: "",
          },
          {
            name: "Katy Perry",
            twitter: "https://twitter.com/katyperry",
            date_joined: "2019-01-02",
            photo: "https://lowcode.s3-us-west-2.amazonaws.com/katy_perry.png",
            email: "katycat@hotmail.com",
            action: "",
          },
          {
            name: "Ryan Gosling",
            twitter: "https://twitter.com/RyanGosling",
            date_joined: "2019-01-03",
            photo:
              "https://lowcode.s3-us-west-2.amazonaws.com/ryan_gosling.png",
            email: "ryan_the_notebook@gmail.com",
            action: "",
          },
          {
            name: "Dua Lipa",
            twitter: "https://twitter.com/DUALIPA",
            date_joined: "2019-01-07",
            photo: "https://lowcode.s3-us-west-2.amazonaws.com/dua_lipa.png",
            email: "dua_levitating@yahoo.com",
            action: "",
          },
          {
            name: "Beyonce Knowles",
            twitter: "https://twitter.com/Beyonce",
            date_joined: "2019-01-09",
            photo:
              "https://lowcode.s3-us-west-2.amazonaws.com/beyonce_knowles.png",
            email: "beyonce_run_the_world@hotmail.com",
            action: "",
          },
          {
            name: "Lopez Jennifer",
            twitter: "https://twitter.com/JLo",
            date_joined: "2019-01-04",
            photo:
              "https://lowcode.s3-us-west-2.amazonaws.com/jennifer_lopez.png",
            email: "jenny_from_the_block@yahoo.com",
            action: "",
          },
        ],
        null,
        2,
      ),
    },
    DROP_DOWN_WIDGET: {
      height: Dimension.gridUnit(estimateInitialDropdownWidgetHeightGU(theme)),
      width: Dimension.gridUnit(3 * COLUMN_WIDTH_MIGRATION),
      label: "City",
      placeholderText: "Select an option",
      // TODO: Remove this as the UI sets `isMultiple: true` instead
      // selectionType: "SINGLE_SELECT",
      options: JSON.stringify(
        [
          {
            label: "New York",
            value: "NEW YORK",
          },
          {
            label: "Toronto",
            value: "TORONTO",
          },
        ],
        null,
        2,
      ),
      isVertical: true,
      widgetName: "Dropdown",
      defaultOptionValue: "TORONTO",
      isVisible: true,
      collapseWhenHidden: true,
      animateLoading: true,
    },
    CHECKBOX_WIDGET: {
      height: Dimension.fitContent(
        estimateInitialCheckboxWidgetHeightGU(theme) ??
          1 * ROW_HEIGHT_MIGRATION,
      ),
      width: Dimension.gridUnit(2 * COLUMN_WIDTH_MIGRATION),
      label: "Label",
      defaultCheckedState: true,
      widgetName: "Checkbox",
      isVisible: true,
      collapseWhenHidden: true,
      animateLoading: true,
    },
    SWITCH_WIDGET: {
      height: Dimension.fitContent(
        estimateInitialSwitchWidgetHeightGU(theme) ?? 1 * ROW_HEIGHT_MIGRATION,
      ),
      width: Dimension.gridUnit(2 * COLUMN_WIDTH_MIGRATION),
      label: "Label",
      labelPosition: WidgetLabelPosition.RIGHT,
      defaultSwitchState: true,
      widgetName: "Switch",
      isVisible: true,
      collapseWhenHidden: true,
      animateLoading: true,
    },
    RADIO_GROUP_WIDGET: {
      height: Dimension.fitContent(4 * ROW_HEIGHT_MIGRATION),
      width: Dimension.gridUnit(2 * COLUMN_WIDTH_MIGRATION),
      label: "Select a state",
      options: [
        { label: "New York", value: "NY" },
        { label: "California", value: "CA" },
        { label: "Texas", value: "TX" },
      ],
      isVertical: true,
      isVisible: true,
      collapseWhenHidden: true,
      animateLoading: true,
      defaultOptionValue: "TX",
      widgetName: "RadioGroup",
    },
    FILE_PICKER_WIDGET: {
      height: Dimension.gridUnit(6 * ROW_HEIGHT_MIGRATION),
      width: Dimension.gridUnit(6 * COLUMN_WIDTH_MIGRATION),
      files: [],
      selectionType: undefined,
      allowedFileTypes: ["*"],
      widgetName: "FilePicker",
      isVisible: true,
      collapseWhenHidden: true,
      animateLoading: true,
    },
    TABS_WIDGET: {
      height: Dimension.gridUnit(7 * ROW_HEIGHT_MIGRATION),
      width: Dimension.gridUnit(8 * COLUMN_WIDTH_MIGRATION),
      shouldScrollContents: true,
      widgetName: "Tabs",
      tabs: [
        { label: "Tab 1", id: "tab1", widgetId: "" },
        { label: "Tab 2", id: "tab2", widgetId: "" },
      ],
      shouldShowTabs: true,
      defaultTab: "Tab 1",
      isVisible: true,
      collapseWhenHidden: true,
    },
    SLIDEOUT_WIDGET: {
      // note that this is the maximum size that is used for the button
      // that gets created with the slideout, and not the slideout widget itself
      height: Dimension.gridUnit(1 * ROW_HEIGHT_MIGRATION),
      width: Dimension.gridUnit(2 * COLUMN_WIDTH_MIGRATION),
      detachFromLayout: true,
      canOutsideClickClose: true,
      hasBackdrop: true,
      shouldScrollContents: true,
      widgetName: "Slideout",
      children: [],
      isVisible: undefined,
      blueprint: {
        view: [
          {
            type: "SECTION_WIDGET",
            position: POS_ZERO,
            props: {
              detachFromLayout: true,
              isVisible: true,
              collapseWhenHidden: true,
              stackColumnsAt: Breakpoints.NEVER,
              canExtend: true,
              dragDisabled: true,
              height: {
                mode: Dimension.fitContent(
                  SectionDefaults.DEFAULT_SECTION_GRID_ROWS,
                ),
              },
              blueprint: {
                view: [
                  {
                    type: "CANVAS_WIDGET",
                    position: POS_ZERO,
                    props: {
                      detachFromLayout: true,
                      canExtend: true,
                      isVisible: true,
                      collapseWhenHidden: true,
                      isDisabled: false,
                      shouldScrollContents: true,
                      children: [],

                      blueprint: {
                        view: [
                          {
                            type: "TEXT_WIDGET",
                            position: POS_ZERO,
                            size: {
                              height: Dimension.fitContent(3),
                              width: Dimension.minus(
                                SLIDEOUT_DEFAULT_COLUMNS,
                                Dimension.gridUnit(10),
                              ).asFirst(),
                            },
                            props: {
                              text: "Slideout Title",
                              textStyle: "HEADING_1",
                              textProps: {
                                textStyle: {
                                  variant: "heading3",
                                },
                              },
                            },
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            },
          },
          {
            type: "SECTION_WIDGET",
            position: POS_ZERO,
            props: {
              detachFromLayout: true,
              isVisible: true,
              collapseWhenHidden: true,
              stackColumnsAt: Breakpoints.NEVER,
              canExtend: true,
              dragDisabled: true,
              height: {
                mode: Dimension.fitContent(
                  SectionDefaults.DEFAULT_SECTION_GRID_ROWS,
                ),
              },
              blueprint: {
                view: [
                  {
                    type: "CANVAS_WIDGET",
                    position: POS_ZERO,
                    props: {
                      detachFromLayout: true,
                      canExtend: true,
                      isVisible: true,
                      collapseWhenHidden: true,
                      isDisabled: false,
                      shouldScrollContents: true,
                      children: [],
                    },
                  },
                ],
              },
            },
          },
        ],
      },
    },
    MODAL_WIDGET: {
      height: Dimension.gridUnit(3),
      width: Dimension.gridUnit(12),
      detachFromLayout: true,
      hasBackdrop: true,
      canOutsideClickClose: true,
      shouldScrollContents: true,
      widgetName: "Modal",
      children: [],
      isVisible: undefined,
      widthPreset: ModalSize.MEDIUM,
      heightPreset: ModalSize.MEDIUM,
      blueprint: {
        view: [
          {
            type: "SECTION_WIDGET",
            position: POS_ZERO,
            props: {
              widgetName: "Modal Header",
              detachFromLayout: true,
              isVisible: true,
              collapseWhenHidden: true,
              stackColumnsAt: Breakpoints.NEVER,
              canExtend: true,
              dragDisabled: true,
              height: {
                mode: Dimension.fitContent(
                  SectionDefaults.DEFAULT_SECTION_GRID_ROWS,
                ),
              },
              blueprint: {
                view: [
                  {
                    type: "CANVAS_WIDGET",
                    position: POS_ZERO,
                    props: {
                      detachFromLayout: true,
                      canExtend: true,
                      isVisible: true,
                      collapseWhenHidden: true,
                      isDisabled: false,
                      isInModal: true,
                      shouldScrollContents: true,
                      children: [],
                      blueprint: {
                        view: [
                          {
                            type: "TEXT_WIDGET",
                            position: POS_ZERO,
                            size: {
                              height: Dimension.fitContent(3),
                              width: Dimension.gridUnit(MODAL_COLUMNS - 5),
                            },
                            props: {
                              text: "Modal Title",
                              textStyle: "HEADING_1",
                              textProps: {
                                textStyle: {
                                  variant: "heading3",
                                },
                              },
                            },
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            },
          },
          {
            type: "SECTION_WIDGET",
            position: POS_ZERO,
            props: {
              detachFromLayout: true,
              isVisible: true,
              collapseWhenHidden: true,
              stackColumnsAt: Breakpoints.NEVER,
              canExtend: true,
              dragDisabled: true,
              height: {
                mode: Dimension.fitContent(
                  SectionDefaults.DEFAULT_SECTION_GRID_ROWS,
                ),
              },
              minHeight: Dimension.gridUnit(21),
              blueprint: {
                view: [
                  {
                    type: "CANVAS_WIDGET",
                    position: POS_ZERO,
                    props: {
                      detachFromLayout: true,
                      canExtend: true,
                      isVisible: true,
                      collapseWhenHidden: true,
                      isDisabled: false,
                      isInModal: true,
                      shouldScrollContents: true,
                      children: [],
                    },
                  },
                ],
              },
            },
          },
          {
            type: "SECTION_WIDGET",
            position: POS_ZERO,
            props: {
              detachFromLayout: true,
              isVisible: true,
              collapseWhenHidden: true,
              stackColumnsAt: Breakpoints.NEVER,
              canExtend: true,
              dragDisabled: true,
              height: {
                mode: Dimension.fitContent(
                  SectionDefaults.DEFAULT_SECTION_GRID_ROWS,
                ),
              },
              blueprint: {
                view: [
                  {
                    type: "CANVAS_WIDGET",
                    position: POS_ZERO,
                    props: {
                      detachFromLayout: true,
                      canExtend: true,
                      isVisible: true,
                      collapseWhenHidden: true,
                      isDisabled: false,
                      isInModal: true,
                      shouldScrollContents: true,
                      children: [],
                      blueprint: {
                        view: [
                          {
                            type: "BUTTON_WIDGET",
                            position: {
                              left: Dimension.gridUnit(MODAL_COLUMNS - 42),
                              top: Dimension.gridUnit(0),
                            },
                            size: {
                              height: Dimension.fitContent(
                                estimateInitialButtonWidgetHeightGU(theme) ??
                                  1 * ROW_HEIGHT_MIGRATION,
                              ),
                              width: Dimension.gridUnit(20),
                            },
                            props: {
                              text: "Cancel",
                              buttonStyle: "SECONDARY_BUTTON",
                            },
                          },
                          {
                            type: "BUTTON_WIDGET",
                            position: {
                              left: Dimension.gridUnit(MODAL_COLUMNS - 20),
                              top: Dimension.gridUnit(0),
                            },
                            size: {
                              height: Dimension.fitContent(
                                estimateInitialButtonWidgetHeightGU(theme) ??
                                  1 * ROW_HEIGHT_MIGRATION,
                              ),
                              width: Dimension.gridUnit(20),
                            },
                            props: {
                              text: "Confirm",
                              buttonStyle: "PRIMARY_BUTTON",
                            },
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            },
          },
        ],
      },
    },
    CANVAS_WIDGET: {
      height: Dimension.gridUnit(0 * ROW_HEIGHT_MIGRATION),
      width: Dimension.gridUnit(0 * COLUMN_WIDTH_MIGRATION),
      widgetName: "Canvas",
      isVisible: true,
      collapseWhenHidden: true,
      // TODO (Layouts): Is this ok to add for all canvas widgets? We definitely need it for Section Columns, otherwise we need
      // to change BaseWidget to treat Columns as special case
      detachFromLayout: true,
    },
    PAGE_WIDGET: {
      // TODO: Should we have a new WidgetProps type for PAGE_WIDGET that doesn't have rows and columns?
      height: Dimension.gridUnit(0 * ROW_HEIGHT_MIGRATION),
      width: Dimension.gridUnit(0 * COLUMN_WIDTH_MIGRATION),
      widgetName: "Page",
      isVisible: true,
      collapseWhenHidden: true,
    },
    SECTION_WIDGET: {
      // TODO: Should we have a new WidgetProps type for SECTION_WIDGET that doesn't have rows and columns?
      widgetName: "Section",
      isVisible: true,
      collapseWhenHidden: true,
      stackColumnsAt: Breakpoints.MOBILE,
      top: Dimension.gridUnit(0),
      left: Dimension.gridUnit(0),
      height: Dimension.fitContent(SectionDefaults.DEFAULT_SECTION_GRID_ROWS),
      width: Dimension.gridUnit(0),
      detachFromLayout: true,
      canExtend: true,
      blueprint: {
        view: [
          {
            type: "CANVAS_WIDGET",
            position: POS_ZERO,
            props: {
              spacing: CanvasDefaults.SPACING,
              canExtend: true,
              children: [],
              shouldScrollContents: true,
            },
          },
        ],
      },
    },
    CHART_WIDGET: {
      height: Dimension.gridUnit(9 * ROW_HEIGHT_MIGRATION),
      width: Dimension.gridUnit(6 * COLUMN_WIDTH_MIGRATION),
      headerTitle: "My chart",
      widgetName: "Chart",
      animateLoading: true,
      columnInfo: [],
      chartDefinition: "ui",
      chartData: [
        {
          city: "Toronto",
          population: 2930000,
          country: "Canada",
        },
        {
          city: "New York",
          population: 8419000,
          country: "USA",
        },
        {
          city: "Vancouver",
          population: 675218,
          country: "Canada",
        },
        {
          city: "San Francisco",
          population: 874961,
          country: "USA",
        },
      ],
      plotlyChartJson: {
        data: [
          {
            type: "bar",
            x: [1, 2, 3],
            y: [2, 5, 3],
          },
        ],
        layout: {},
      },
      chartType: "COLUMN_CHART",
      x: {
        field: "",
        dataType: "auto",
        axisTitle: "",
      },
      y: {
        axisTitle: "",
        dataType: "number",
        notation: "standard",
        aggregation: undefined,
        series: [{ field: "" }],
      },
      groupBy: {
        field: "",
      },
      size: {
        field: "",
      },
      isVisible: true,
      collapseWhenHidden: true,
    },
    FORM_BUTTON_WIDGET: {
      height: Dimension.gridUnit(1 * ROW_HEIGHT_MIGRATION),
      width: Dimension.gridUnit(3 * COLUMN_WIDTH_MIGRATION),
      widgetName: "FormButton",
      text: "Submit",
      isDefaultClickDisabled: true,
      isVisible: true,
      collapseWhenHidden: true,
      animateLoading: true,
    },
    FORM_WIDGET: {
      height: Dimension.fitContent(13 * ROW_HEIGHT_MIGRATION),
      width: Dimension.gridUnit(7 * COLUMN_WIDTH_MIGRATION),
      widgetName: "Form",
      isVisible: true,
      collapseWhenHidden: true,
      children: [],
      blueprint: {
        view: [
          {
            type: "CANVAS_WIDGET",
            position: POS_ZERO,
            props: {
              canExtend: false,
              detachFromLayout: true,
              children: [],
              blueprint: {
                view: [
                  {
                    type: "TEXT_WIDGET",
                    size: {
                      height: Dimension.fitContent(3),
                      width: (parent: WidgetProps) =>
                        Dimension.gridUnit(parent.gridColumns ?? 0),
                    },
                    position: POS_ZERO,
                    props: {
                      text: "Form",
                      textStyle: "HEADING_1",
                      textProps: {
                        textStyle: {
                          variant: "heading3",
                        },
                      },
                    },
                  },
                  {
                    type: "FORM_BUTTON_WIDGET",
                    size: {
                      height: Dimension.gridUnit(1 * ROW_HEIGHT_MIGRATION),
                      width: Dimension.gridUnit(1 * COLUMN_WIDTH_MIGRATION),
                    },
                    position: {
                      bottom: (parent: WidgetProps) =>
                        Dimension.gridUnit(
                          (parent.height.value ?? 0) -
                            getContainerPaddingHeightGU(theme),
                        ),
                      right: (parent: WidgetProps) =>
                        Dimension.gridUnit(parent.gridColumns ?? 0),
                    },
                    props: {
                      text: "Submit",
                      buttonStyle: "PRIMARY_BUTTON",
                      disabledWhenInvalid: true,
                      resetFormOnClick: true,
                    },
                  },
                  {
                    type: "FORM_BUTTON_WIDGET",
                    size: {
                      height: Dimension.gridUnit(1 * ROW_HEIGHT_MIGRATION),
                      width: Dimension.gridUnit(1 * COLUMN_WIDTH_MIGRATION),
                    },
                    position: {
                      bottom: (parent: WidgetProps) =>
                        Dimension.gridUnit(
                          (parent.height.value ?? 0) -
                            getContainerPaddingHeightGU(theme),
                        ),
                      right: (parent: WidgetProps) =>
                        Dimension.gridUnit(
                          (parent.gridColumns ?? 0) -
                            1 * COLUMN_WIDTH_MIGRATION,
                        ),
                    },
                    props: {
                      text: "Reset",
                      buttonStyle: "SECONDARY_BUTTON",
                      disabledWhenInvalid: false,
                      resetFormOnClick: true,
                    },
                  },
                ],
              },
            },
          },
        ],
      },
    },
    MAP_WIDGET: {
      height: Dimension.gridUnit(6 * ROW_HEIGHT_MIGRATION),
      width: Dimension.gridUnit(4 * COLUMN_WIDTH_MIGRATION),
      isDisabled: false,
      isVisible: true,
      collapseWhenHidden: true,
      widgetName: "Map",
      enableSearch: true,
      zoomLevel: 50,
      enablePickLocation: true,
      allowZoom: true,
      mapCenter: {
        lat: 40.7127753,
        long: -74.0059728,
      },
      defaultMarkers: [
        {
          lat: 40.7128,
          long: -74.006,
          title: "City Hall",
        },
      ],
    },
    SKELETON_WIDGET: {
      isLoading: true,
      height: Dimension.gridUnit(1 * ROW_HEIGHT_MIGRATION),
      width: Dimension.gridUnit(1 * COLUMN_WIDTH_MIGRATION),
      widgetName: "Skeleton",
      isVisible: true,
      collapseWhenHidden: true,
    },
    GRID_WIDGET: {
      isLoading: true,
      animateLoading: true,
      height: Dimension.gridUnit(17 * ROW_HEIGHT_MIGRATION),
      width: Dimension.gridUnit(11 * COLUMN_WIDTH_MIGRATION),
      widgetName: "Grid",
      title: "Listings",
      isSearchable: true,
      enhancements: true,
      isVisible: true,
      collapseWhenHidden: true,
      gridData: [
        {
          name: "Hollywood Mansion",
          stars: 4.9,
          pricePerNight: 956,
          img: "https://superblocks.s3.us-west-2.amazonaws.com/img/demo/listing1.jpg",
        },
        {
          name: "Oceanside Villa",
          stars: 4.8,
          pricePerNight: 1025,
          img: "https://superblocks.s3.us-west-2.amazonaws.com/img/demo/listing2.jpg",
        },
        {
          name: "Bel Air Oasis",
          stars: 4.7,
          pricePerNight: 878,
          img: "https://superblocks.s3.us-west-2.amazonaws.com/img/demo/listing3.jpg",
        },
        {
          name: "Spanish Castle",
          stars: 4.6,
          pricePerNight: 678,
          img: "https://superblocks.s3.us-west-2.amazonaws.com/img/demo/listing4.jpg",
        },
        {
          name: "LA Mansion",
          stars: 4.8,
          pricePerNight: 710,
          img: "https://superblocks.s3.us-west-2.amazonaws.com/img/demo/listing5.jpg",
        },
        {
          name: "Beachfront Home",
          stars: 4.6,
          pricePerNight: 678,
          img: "https://superblocks.s3.us-west-2.amazonaws.com/img/demo/listing6.jpg",
        },
        {
          name: "Hollywood Resort",
          stars: 4.8,
          pricePerNight: 820,
          img: "https://superblocks.s3.us-west-2.amazonaws.com/img/demo/listing1.jpg",
        },
        {
          name: "LA Mansion",
          stars: 4.6,
          pricePerNight: 925,
          img: "https://superblocks.s3.us-west-2.amazonaws.com/img/demo/listing3.jpg",
        },
        {
          name: "Seattle Condo",
          stars: 4.7,
          pricePerNight: 829,
          img: "https://superblocks.s3.us-west-2.amazonaws.com/img/demo/listing5.jpg",
        },
      ],
      canExtend: false,
      gridCellScrollable: false,
      columnCount: 3,
      dynamicBindingPathList: [],
      dynamicTriggerPathList: [],
      gridBindings: {
        // To get the bindings working for currentCell.name, the list widget creates a special function
        // that will iterate over each list item and store the results here
        // [ChildWidgetName.key]: bound function
      },
      children: [],
      blueprint: {
        view: [
          {
            type: "CANVAS_WIDGET",
            position: POS_ZERO,
            props: {
              padding: {
                left: Dimension.px(0),
                right: Dimension.px(0),
                top: Dimension.px(0),
                bottom: Dimension.px(0),
              },
              canExtend: false,
              detachFromLayout: true,
              dropDisabled: true,
              resizeDisabled: true,
              // Clicking anywhere inside the grid should open the grid's properties,
              // not show users the implementation details
              openParentPropertyPane: true,
              disablePropertyPane: true,
              children: [],
              blueprint: {
                // Each cell in the grid is a container. This subtree is copied N times, for each item.
                view: [
                  {
                    type: "CONTAINER_WIDGET",
                    size: {
                      height: Dimension.gridUnit(21),
                      width: Dimension.gridUnit(22),
                    },
                    position: POS_ZERO,
                    props: {
                      // The first item will be resizable
                      resizeDisabled: false,
                      shouldScrollContents: true,
                      isDeletable: false,
                      dragDisabled: true,
                      disallowCopy: true,
                      disablePropertyPane: true,
                      openParentPropertyPane: true,
                      padding: {
                        left: Dimension.px(0),
                        right: Dimension.px(0),
                        top: Dimension.px(0),
                        bottom: Dimension.px(0),
                      },
                      children: [],
                      blueprint: {
                        view: [
                          {
                            type: "CANVAS_WIDGET",
                            position: POS_ZERO,
                            size: {
                              height: Dimension.gridUnit(
                                4 * ROW_HEIGHT_MIGRATION,
                              ),
                              width: Dimension.gridUnit(22),
                            },
                            props: {
                              canExtend: false,
                              detachFromLayout: true,
                              dragDisabled: true,
                              resizeDisabled: true,
                              ignoreCollision: true,
                              openParentPropertyPane: true,
                              disablePropertyPane: true,
                              children: [],
                              blueprint: {
                                view: [
                                  {
                                    type: "IMAGE_WIDGET",
                                    size: {
                                      height: Dimension.gridUnit(
                                        4 * ROW_HEIGHT_MIGRATION,
                                      ),
                                      width: Dimension.gridUnit(22),
                                    },
                                    position: POS_ZERO,
                                    props: {
                                      fillContainer: true,
                                      imageShape: "RECTANGLE",
                                      maxZoomLevel: 1,
                                      image: "{{currentCell.img}}",
                                      dynamicBindingPathList: [
                                        { key: "image" },
                                      ],
                                      dynamicTriggerPathList: [],
                                    },
                                  },
                                  {
                                    type: "TEXT_WIDGET",
                                    size: {
                                      height: Dimension.gridUnit(
                                        1 * ROW_HEIGHT_MIGRATION,
                                      ),
                                      width: Dimension.gridUnit(22),
                                    },
                                    position: {
                                      top: Dimension.gridUnit(
                                        4 * ROW_HEIGHT_MIGRATION,
                                      ),
                                      left: Dimension.gridUnit(
                                        0 * COLUMN_WIDTH_MIGRATION,
                                      ),
                                    },
                                    props: {
                                      text: "{{currentCell.name}}",
                                      textStyle: "HEADING_2",
                                      textAlign: "LEFT",
                                      verticalAlign: VerticalAlign.BOTTOM,
                                      dynamicBindingPathList: [{ key: "text" }],
                                      dynamicTriggerPathList: [],
                                    },
                                  },
                                  {
                                    type: "TEXT_WIDGET",
                                    size: {
                                      height: Dimension.gridUnit(
                                        1 * ROW_HEIGHT_MIGRATION,
                                      ),
                                      width: Dimension.gridUnit(16),
                                    },
                                    position: {
                                      top: Dimension.gridUnit(
                                        5 * ROW_HEIGHT_MIGRATION,
                                      ),
                                      left: Dimension.gridUnit(
                                        0 * COLUMN_WIDTH_MIGRATION,
                                      ),
                                    },
                                    props: {
                                      text: "{{currentCell.stars}} stars",
                                      textStyle: "NORMAL_TEXT",
                                      textAlign: "LEFT",
                                      dynamicBindingPathList: [{ key: "text" }],
                                      dynamicTriggerPathList: [],
                                    },
                                  },
                                  {
                                    type: "TEXT_WIDGET",
                                    size: {
                                      height: Dimension.gridUnit(
                                        1 * ROW_HEIGHT_MIGRATION,
                                      ),
                                      width: Dimension.gridUnit(6),
                                    },
                                    position: {
                                      top: Dimension.gridUnit(
                                        5 * ROW_HEIGHT_MIGRATION,
                                      ),
                                      left: Dimension.gridUnit(16),
                                    },
                                    props: {
                                      text: "${{currentCell.pricePerNight}}",
                                      textStyle: "NORMAL_TEXT",
                                      textAlign: "RIGHT",
                                      dynamicBindingPathList: [{ key: "text" }],
                                      dynamicTriggerPathList: [],
                                    },
                                  },
                                ],
                              },
                            },
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            },
          },
        ],
      },
    },
    CODE_WIDGET: {
      height: Dimension.gridUnit(8 * ROW_HEIGHT_MIGRATION),
      width: Dimension.gridUnit(8 * COLUMN_WIDTH_MIGRATION),
      widgetName: "CodeEditor",
      label: "Label",
      mode: CodeWidgetLanguages.HJSON,
      isVisible: true,
      collapseWhenHidden: true,
      animateLoading: true,
      initialValue: `[
  {
    "city": "Toronto",
    "population": 2930000,
    "country": "Canada"
  },
  {
    "city": "New York",
    "population": 8419000,
    "country": "USA"
  },
  {
    "city": "Vancouver",
    "population": 675218,
    "country": "Canada"
  },
  {
    "city": "San Francisco",
    "population": 874961,
    "country": "USA"
  }
]`,
      readOnly: false,
      lineWrapping: false,
      showLineNumbers: true,
    },
    PDF_VIEWER_WIDGET: {
      fileUrl: "https://www.spacex.com/media/starship_users_guide_v1.pdf",
      height: Dimension.gridUnit(12 * ROW_HEIGHT_MIGRATION),
      width: Dimension.gridUnit(5 * COLUMN_WIDTH_MIGRATION),
      widgetName: "PDFViewer",
      isVisible: true,
      collapseWhenHidden: true,
      animateLoading: true,
    },
    IFRAME_WIDGET: {
      source: "https://superblocks.com/",
      srcDoc: "",
      height: Dimension.gridUnit(12 * ROW_HEIGHT_MIGRATION),
      width: Dimension.gridUnit(5 * COLUMN_WIDTH_MIGRATION),
      widgetName: "IFrame",
      isVisible: true,
      collapseWhenHidden: true,
      animateLoading: true,
    },
    DIFF_WIDGET: {
      docsUrl:
        "https://docs.superblocks.com/components/diff-viewer#use-diff-viewer-to-compare-data",
      height: Dimension.gridUnit(8 * ROW_HEIGHT_MIGRATION),
      width: Dimension.gridUnit(8 * COLUMN_WIDTH_MIGRATION),
      widgetName: "Diff",
      originalLabel: "Original",
      originalText: `[
  {
    "city": "Toronto",
    "population": 2930000,
    "country": "Canada"
  },
  {
    "city": "New York",
    "population": 8419000,
    "country": "USA"
  }
]`,
      newLabel: "New",
      newText: `[
  {
    "city": "Toronto",
    "population": 3500000,
    "country": "Canada"
  },
  {
    "city": "New York",
    "population": 8419000,
    "country": "USA"
  },
  {
    "city": "Vancouver",
    "population": 675218,
    "country": "Canada"
  }
]`,
      compareMethod: DiffMethod.LINES,
      isVisible: true,
      collapseWhenHidden: true,
      animateLoading: true,
      isDisabled: false,
      showDiffOnly: false,
    },
    CHAT_WIDGET: {
      height: Dimension.gridUnit(12 * ROW_HEIGHT_MIGRATION),
      width: Dimension.gridUnit(6 * COLUMN_WIDTH_MIGRATION),
      widgetName: "Chat",
      isVisible: true,
      collapseWhenHidden: true,
      header: "Chat",
      placeholderText: "Enter message",
      messageHistory: `[
{
  "content": "When the sun shines, we shine together. Told you I'll be here forever",
  "name": "Rihanna",
  "avatar": "https://lowcode.s3-us-west-2.amazonaws.com/rihanna.jpeg",
  "timestamp": "03/06/2023 3:45:00 PM",
  "role": "user"
},
{
  "content": "Alright, alright, alright",
  "name": "Matthew McConaughey",
  "avatar": "https://lowcode.s3-us-west-2.amazonaws.com/matthew.jpeg",
  "timestamp": "03/06/2023 3:46:00 PM",
  "role": "user"
},
{
  "content": "Hello, it’s me ",
  "name": "Adele",
  "avatar": "https://lowcode.s3-us-west-2.amazonaws.com/adele.jpeg",
  "timestamp": "03/06/2023 3:48:00 PM",
  "role": "user"
},
{
  "content": "I'll be back",
  "name": "Arnold Schwarzenegger",
  "avatar": "https://lowcode.s3-us-west-2.amazonaws.com/arnold_schwarzenegger.jpeg",
  "timestamp": "03/06/2023 3:50:00 PM",
  "role": "user"
},
{
  "content": "I’m not a businessman, I’m a business, man",
  "name": "Jay Z",
  "avatar": "https://lowcode.s3-us-west-2.amazonaws.com/jayz.jpeg",
  "timestamp": "03/06/2023 3:53:00 PM",
  "role": "user"
},
]`,
      enableChatSearch: true,
      enableEnterToSend: true,
      enableImages: true,
      enableEmojis: true,
      showPendingMessage: true,
      enablePendingState: true,
      pendingStateText: "Waiting for ChatGPT...",
      pendingStateTimeout: 1,
      avatarField: "avatar",
      displayNameField: "name",
      timestampField: "timestamp",
      messageField: "content",
      timestampFormat: "hh:mm a",
    },
    CALLOUT_WIDGET: {
      height: Dimension.fitContent(9),
      width: Dimension.gridUnit(36),
      widgetName: "Callout",
      isVisible: true,
      collapseWhenHidden: true,
      animateLoading: true,
      isDismissible: false,
      calloutType: CalloutType.INFO,
      verticalAlign: VerticalAlign.CENTER,
      title: "Important content",
      description:
        "Here are some details about the important content, including some <b>bold</b> and <i>italicized</i> content",
      ctaText: "Click here",
    },
    MENU_WIDGET: {
      isDisabled: false,
      isVisible: true,
      buttonProps: {
        text: "Menu",
        buttonStyle: "PRIMARY_BUTTON",
        iconPosition: "LEFT",
      },
      collapseWhenHidden: true,
      menuProps: {},
      height: Dimension.fitContent(
        estimateInitialButtonWidgetHeightGU(theme) ?? 1 * ROW_HEIGHT_MIGRATION,
      ),
      width: Dimension.gridUnit(2 * COLUMN_WIDTH_MIGRATION),
      widgetName: "Menu",
      childrenType: MenuChildrenType.Manual,
      manualChildren: [
        {
          childrenType: MenuChildrenType.Manual,
          label: "Menu item 1",
          linkTo: LinkToType.Page,
          type: MenuItemType.Link,
        },
        {
          childrenType: MenuChildrenType.Manual,
          label: "Menu item 2",
          linkTo: LinkToType.Page,
          type: MenuItemType.Link,
        },
        {
          childrenType: MenuChildrenType.Manual,
          label: "Menu item 3",
          linkTo: LinkToType.Page,
          type: MenuItemType.Link,
        },
      ],
    },
    LINK_WIDGET: {
      text: "Click me!",
      linkStyle: "LINK",
      linkTo: "customUrl",
      height: Dimension.fitContent(
        estimateInitialTextWidgetHeightGU(theme) ?? 1 * ROW_HEIGHT_MIGRATION,
      ),
      width: Dimension.gridUnit(2 * COLUMN_WIDTH_MIGRATION),
      widgetName: "Link",
      isDisabled: false,
      isVisible: true,
      collapseWhenHidden: true,
      animateLoading: true,
      isDefaultClickDisabled: true,
      docsUrl: "https://docs.superblocks.com/components/link",
      iconPosition: "LEFT",
    },
    CUSTOM_WIDGET: {
      height: Dimension.gridUnit(30),
      width: Dimension.gridUnit(50),
      widgetName: "CustomComponent",
      isVisible: true,
      collapseWhenHidden: true,
    },
    UNREGISTERED_CUSTOM_WIDGET: {
      height: Dimension.gridUnit(1),
      width: Dimension.gridUnit(1),
      widgetName: "UnregisteredCustomComponent",
      isVisible: true,
      collapseWhenHidden: true,
    },
  };
  return blueprints;
};

const customCompBlueprints: Record<
  string,
  Partial<WidgetCreateConfig<WidgetTypes.CUSTOM_WIDGET>>
> = {};

export function registerCustomComponentConfigResponse(
  widgetType: WidgetType,
  widgetNamePrefix: string,
  defaultValues: Record<string, unknown>,
  gridDimensions?: {
    columns: number;
    rows: number;
  },
) {
  customCompBlueprints[widgetType] = {
    widgetName: widgetNamePrefix,
    ...defaultValues,
    dynamicBindingPathList: Object.keys(defaultValues).map((key) => ({
      key,
    })),
    ...gridDimensions,
  };

  createConfigChanged();
}

const getCreateConfig = (
  key: keyof WidgetTypeToPropType,
  flags: Flags,
  theme?: GeneratedTheme,
): WidgetCreateConfig<typeof key> => {
  const blueprints = getBlueprints(flags, theme);

  // If there is a custom component blueprint, merge it with the default blueprint
  if (customCompBlueprints[key]) {
    return {
      ...blueprints[WidgetTypes.CUSTOM_WIDGET],
      ...customCompBlueprints[key],
    };
  } else {
    return blueprints[key];
  }
};

const WidgetConfigResponse: WidgetConfigReducerState = {
  getCreateConfig,
};

export default WidgetConfigResponse;
